import { wsutil } from "../store";
import { getaccessdata } from '../assets/js/accessdata';
import * as AppStorage from '../assets/js/storage';

export const nav = { async retrieve() {
    let data = await getaccessdata();
    let items = [
      {
            name: 'Dashboard',
            url: '/home/dashboard',
            icon: 'fa fa-home',
            hasChild: false
      },

      {
        name: 'Parking Entry',
        url: '/home/parkingentry',
        icon: 'fa fa-car-tunnel',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Gated Parking',
            url: '/home/parkingentry/gatedparking',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },
          {
            name: 'LPR',
            url: '/home/parkingentry/lpr',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children:[
              {
                name: 'Parking',
                url: '/home/parkingentry/lpr/parking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false
              },
              {
                name: 'LPR Images',
                url: '/home/parkingentry/lpr/images/all',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false
              },
              {
                name: 'LPR Unknown',
                url: '/home/parkingentry/lpr/images/unknown',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false
              },
            ]
          },

          {
            name: 'BayLock Parking',
            url: '/home/parkingentry/baylockparking',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          }
        ]
      },

      {
        name: 'Subscriber',
        url: '/home/subscriber',
        icon: 'fa fa-users',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Mobile',
            url: '/home/subscriber/mobile',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Snatch-P',
                url: '/home/subscriber/mobile/snatchp',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Touch N Go QR',
                url: '/home/subscriber/mobile/tngqr',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'BInfinite Pay',
                url: '/home/subscriber/mobile/binfinite',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Car Plate',
                url: '/home/subscriber/mobile/carplate',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'All Subscribers',
                url: '/home/subscriber/mobile/all',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'Corporate',
            url: '/home/subscriber/corporate',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Corporate Account',
                url: '/home/subscriber/corporate/account',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Season',
            url: '/home/subscriber/season',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Vehicle',
            url: '/home/subscriber/vehicle',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'LPR Web',
            url: '/home/subscriber/lprweb',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },
          {
            name: 'Baylock Web',
            url: '/home/subscriber/wlweb',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },
        ]
      },

      {
        name: 'Business',
        url: '/home/business',
        icon: 'fa fa-briefcase',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Car Park Operator',
            url: '/home/business/cpoperator',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Operator',
                url: '/home/business/cpoperator/operator',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'Users',
                url: '/home/business/cpoperator/user',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },
        ]
      },

      /*
      {
        name: 'Location',
        url: '/home/location',
        icon: 'fa fa-location-arrow',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Country',
            url: '/home/location/country',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'State',
            url: '/home/location/state',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'City',
            url: '/home/location/city',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Postcode',
            url: '/home/location/postcode',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          }
        ]
      },
      */

      /*
      {
        name: 'Operator',
        url: '/home/operator',
        icon: 'fa fa-server',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: false,
      },
      */

      {
        name: 'Car Park',
        url: '/home/carpark',
        icon: 'fa fa-car',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Car Park',
            url: '/home/carpark/carpark',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Car Park Rate',
            url: '/home/carpark/ratesetup',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Special Car Park Rate',
            url: '/home/carpark/specialrate',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Bays',
            url: '/home/carpark/bays',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Find My Car',
            url: '/home/carpark/findmycar',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Service User',
            url: '/home/carpark/serviceuser',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },
        ]
      },

      {
        name: 'Boom Gate',
        url: '/home/boomgate',
        icon: 'fa fa-slash',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Controller',
            url: '/home/boomgate/controller',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Controller',
                url: '/home/boomgate/controller/controller',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Offline Transaction',
                url: '/home/boomgate/controller/offlinetransaction',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Boom Gate',
            url: '/home/boomgate/boomgate',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },
        ]
      },

      {
        name: 'BayLock',
        url: '/home/baylock',
        icon: 'fa fa-campground',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Controller',
            url: '/home/baylock/controller',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Controller',
                url: '/home/baylock/controller/controller',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Mainboard',
            url: '/home/baylock/mainboard',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'BayLock',
            url: '/home/baylock/baylock',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },
        ]
      },

      {
        name: 'Marketing',
        url: '/home/marketing',
        icon: 'fa fa-funnel-dollar',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Value Pass',
            url: '/home/marketing/valuepass',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Value Pass',
                url: '/home/marketing/valuepass/valuepass',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Value Pass Units',
                url: '/home/marketing/valuepass/valuepassunits',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'Merchant Reward',
            url: '/home/marketing/merchantreward',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Review Reasons',
                url: '/home/marketing/merchantreward/reviewreasons',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Merchant Reward Slots',
                url: '/home/marketing/merchantreward/slots',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Merchant Rewards',
                url: '/home/marketing/merchantreward/merchantrewards',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Collected Promo Vouchers',
                url: '/home/marketing/merchantreward/collectedpromovouchers',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },
        ]
      },

      {
        name: 'Message',
        url: '/home/message',
        icon: 'fa fa-comment-dots',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'New Message Blast',
            url: '/home/message/blast',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: 'true',
            hasChild: false
          },

          {
            name: 'Push Notification',
            url: '/home/message/pushnotification',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Inbox',
                url: '/home/message/pushnotification/inbox',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Notification',
                url: '/home/message/pushnotification/notification',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Message Category',
                url: '/home/message/pushnotification/messagecategory',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'WhatsApp',
            url: '/home/message/whatsapp',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'WhatsApp',
                url: '/home/message/whatsapp/whatsapp',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Message Template',
                url: '/home/message/whatsapp/messagetemplate',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'SMS',
            url: '/home/message/sms',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: 'true',
            hasChild: false
          }
        ]
      },

      {
        name: 'Transaction',
        url: '/home/transaction',
        icon: 'fa fa-exchange-alt',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Mobile Subscriber',
            url: '/home/transaction/mobilesub',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Purchased Parking Tokens',
                url: '/home/transaction/mobilesub/purchasedparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Transferred Parking Tokens',
                url: '/home/transaction/mobilesub/transferredparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Free Parking Tokens',
                url: '/home/transaction/mobilesub/freeparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Deduct Parking Tokens',
                url: '/home/transaction/mobilesub/deductparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Parking',
                url: '/home/transaction/mobilesub/parking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'LPR Parking',
                url: '/home/transaction/mobilesub/parkinglpr',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Special Rate Parking',
                url: '/home/transaction/mobilesub/specialrateparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Manual Exit',
                url: '/home/transaction/mobilesub/manualexit',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Manual Entry',
                url: '/home/transaction/mobilesub/manualentry',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'LPR Exit',
                url: '/home/transaction/mobilesub/lprexit',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'LPR Manual Entry',
                url: '/home/transaction/mobilesub/lprentry',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Update Plate',
                url: '/home/transaction/mobilesub/updateplate',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Purchased Value Pass',
                url: '/home/transaction/mobilesub/purchasevaluepass',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Value Pass Units',
                url: '/home/transaction/mobilesub/valuepassunits',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Transferred Value Pass',
                url: '/home/transaction/mobilesub/valuepasstransfer',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Used Value Pass',
                url: '/home/transaction/mobilesub/usedvaluepass',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'History',
                url: '/home/transaction/mobilesub/history',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'Corporate Subscriber',
            url: '/home/transaction/corporatesubscriber',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Purchased Merchant Rewards',
                url: '/home/transaction/corporatesubscriber/purchasedmerchantrewards',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Collected Promo Vouchers',
                url: '/home/transaction/corporatesubscriber/collectedpromovouchers',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Redeemed Promo Vouchers',
                url: '/home/transaction/corporatesubscriber/redeemedpromovouchers',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },
          {
            name: 'LPR Web',
            url: '/home/transaction/lprweb',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL:'',
            permission: true,
            hasChild: true,
            children:[
              {
                name: 'Payment',
                url: '/home/transaction/lprweb/payment',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'BayLock Parking',
            url: '/home/transaction/baylockparking',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'All Parking',
                url: '/home/transaction/baylockparking/allparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'App Parking',
                url: '/home/transaction/baylockparking/appparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Pole Parking',
                url: '/home/transaction/baylockparking/poleparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Web Payment',
                url: '/home/transaction/baylockparking/webpayment',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'No Payment',
                url: '/home/transaction/baylockparking/nopayment',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Grace Period',
                url: '/home/transaction/baylockparking/grace',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Manual Exit',
                url: '/home/transaction/baylockparking/manualexit',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Manual Trigger',
                url: '/home/transaction/baylockparking/manualtrigger',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'SMS',
            url: '/home/transaction/sms',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Sent Activation Code',
                url: '/home/transaction/sms/sentactivationcode',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Verified Activation Code',
                url: '/home/transaction/sms/verifiedactivationcode',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'SMS Notification',
                url: '/home/transaction/sms/smsnotification',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'All SMS',
                url: '/home/transaction/sms/allsms',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'Payment Gateway',
            url: '/home/transaction/paymentgateway',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'iPay88 Online',
                url: '/home/transaction/paymentgateway/ipay88online',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Boost',
                url: '/home/transaction/paymentgateway/boost',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'BInfinite Pay',
                url: '/home/transaction/paymentgateway/binfinitepay',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Touch N Go e-Wallet',
                url: '/home/transaction/paymentgateway/tng',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Duit Now',
                url: '/home/transaction/paymentgateway/duitnow',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Touch N Go QR',
                url: '/home/transaction/paymentgateway/tngqr',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              
              {
                name: 'Touch N Go',
                url: '/home/transaction/paymentgateway/tngcard',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Visa Master',
                url: '/home/transaction/paymentgateway/visamaster',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          }
        ]
      },

      {
        name: 'Report',
        url: '/home/report',
        icon: 'fa fa-clipboard',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Parking Tokens',
            url: '/home/report/parkingtokens',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Purchased Parking Tokens',
                url: '/home/report/parkingtokens/purchasedparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Free Parking Tokens',
                url: '/home/report/parkingtokens/freeparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Deducted Parking Tokens',
                url: '/home/report/parkingtokens/deductedparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Parking',
            url: '/home/report/parkingr',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Casual Parking',
                url: '/home/report/parkingr/casualparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Special Rate Parking',
                url: '/home/report/parkingr/specialrateparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Season Parking',
                url: '/home/report/parkingr/seasonparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Manual Exit',
                url: '/home/report/parkingr/manualexit',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Manual Entry',
                url: '/home/report/parkingr/manualentry',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'LPR Exit',
                url: '/home/report/parkingr/lprexit',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'LPR Manual Entry',
                url: '/home/report/parkingr/lprentry',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Update Plate',
                url: '/home/report/parkingr/updateplate',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'All Parking',
                url: '/home/report/parkingr/allparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },
          {
            name: 'LPR Web',
            url: '/home/report/lprweb',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Payment',
                url: '/home/report/lprweb/payment',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'BayLock Parking',
            url: '/home/report/baylockparking',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'All Parking',
                url: '/home/report/baylockparking/allparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'App Casual Parking',
                url: '/home/report/baylockparking/appparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Pole Casual Parking',
                url: '/home/report/baylockparking/poleparking',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Web Casual Parking',
                url: '/home/report/baylockparking/webpayment',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'No Payment',
                url: '/home/report/baylockparking/nopayment',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Grace Period',
                url: '/home/report/baylockparking/grace',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Manual Exit',
                url: '/home/report/baylockparking/manualexit',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Value Pass',
            url: '/home/report/valuepass',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Purchased Value Pass',
                url: '/home/report/valuepass/purchasedvaluepass',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Transferred Value Pass',
                url: '/home/report/valuepass/transferredvaluepass',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Used Value Pass',
                url: '/home/report/valuepass/usedvaluepass',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Merchant Rewards',
            url: '/home/report/merchantrewards',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Purchased Merchant Rewards',
                url: '/home/report/merchantrewards/purchasedmerchantrewards',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Collected Promo Vouchers',
                url: '/home/report/merchantrewards/collectedpromovouchers',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Redeemed Promo Vouchers',
                url: '/home/report/merchantrewards/redeemedpromovouchers',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },
          
          {
            name: 'Payment Gateway',
            url: '/home/report/paymentgateway',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'iPay88 Online',
                url: '/home/report/paymentgateway/ipay88online',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Boost',
                url: '/home/report/paymentgateway/boost',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'BInfinite Pay',
                url: '/home/report/paymentgateway/binfinitepay',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Touch N Go e-Wallet',
                url: '/home/report/paymentgateway/tng',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Duit Now',
                url: '/home/report/paymentgateway/duitnow',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Touch N Go QR',
                url: '/home/report/paymentgateway/tngqr',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Touch N Go',
                url: '/home/report/paymentgateway/tngcard',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Visa Master',
                url: '/home/report/paymentgateway/visamaster',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'Operator Remittance',
            url: '/home/report/operatorremittance',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'All Products',
                url: '/home/report/operatorremittance/allproducts',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'All Payments',
                url: '/home/report/operatorremittance/allpayments',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },
        ]
      },

      {
        name: 'Finance',
        url: '/home/finance',
        icon: 'fa fa-dollar-sign',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Parking Tokens',
            url: '/home/finance/parkingtokens',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Parking Tokens Menu',
                url: '/home/finance/parkingtokens/parkingtokensmenu',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Free Parking Tokens',
                url: '/home/finance/parkingtokens/freeparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Deduct Parking Tokens',
                url: '/home/finance/parkingtokens/deductparkingtokens',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },

          {
            name: 'Setting',
            url: '/home/finance/setting',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Product Services Fees',
                url: '/home/finance/setting/productservicesfees',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Operator Remittance Finance',
                url: '/home/finance/setting/operatorremittancefinance',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'BInfinite Pay TID',
                url: '/home/finance/setting/binfinitepaytid',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Duit Now',
                url: '/home/finance/setting/duitnow',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Payment Gateway',
            url: '/home/finance/paymentgateway',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Touch N Go e-Wallet',
                url: '/home/finance/paymentgateway/tng',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Touch N Go QR',
                url: '/home/finance/paymentgateway/tngqr',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Duit Now',
                url: '/home/finance/paymentgateway/duitnow',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },
          {
            name: 'EInvoice',
            url: '/home/finance/lhdn',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Tax Setting',
                url: '/home/finance/lhdn/settings',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Consolidated Bills',
                url: '/home/finance/lhdn/submitted/doc',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'E-Invoice Documents',
                url: '/home/finance/lhdn/einvoice',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Tax Transaction',
                url: '/home/finance/lhdn/trxn',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
              {
                name: 'Submission',
                url: '/home/finance/lhdn/submission',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
            ]
          },
        ]
      },

      {
        name: 'SP Console Setting',
        url: '/home/spconsolesetting',
        icon: 'fa fa-user',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'User Management',
            url: '/home/spconsolesetting/usersetting',
            icon: 'fa fa-user-friends',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Profile Type',
                url: '/home/spconsolesetting/usersetting/profiletype',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'Role',
                url: '/home/spconsolesetting/usersetting/profilerole',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },

              {
                name: 'Role Permission',
                url: '/home/spconsolesetting/usersetting/users',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },
        ]
      },

      {
        name: 'Customer',
        url: '/home/customer',
        icon: 'fa fa-user-friends',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'Users',
            url: '/home/customer/users',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Profile Type',
            url: '/home/customer/profiletype',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Profile Role',
            url: '/home/customer/profilerole',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          }
        ]
      },

      {
        name: 'Administration',
        url: '/home/administration',
        icon: 'fa fa-user-cog',
        API_CONTROL_URL: '',
        permission: true,
        hasChild: true,
        children: [
          {
            name: 'System',
            url: '/home/administration/system',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Location',
            url: '/home/administration/location',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Country',
                url: '/home/administration/location/country',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'State',
                url: '/home/administration/location/state',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'City',
                url: '/home/administration/location/city',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'Postcode',
                url: '/home/administration/location/postcode',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'Holiday',
            url: '/home/administration/holiday',
            icon: 'fa fa-dot-circle',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: false,
          },

          {
            name: 'Parking Entry',
            url: '/home/administration/parkingentry',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Manual Exit Category',
                url: '/home/administration/parkingentry/category',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'Manual Exit Issue',
                url: '/home/administration/parkingentry/issue',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },

          {
            name: 'User Management',
            url: '/home/administration/usermanagement',
            icon: 'fa fa-location-arrow',
            API_CONTROL_URL: '',
            permission: true,
            hasChild: true,
            children: [
              {
                name: 'Internal Users',
                url: '/home/administration/usermanagement/internalusers',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'Profile Type',
                url: '/home/administration/usermanagement/profiletype',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'Role',
                url: '/home/administration/usermanagement/role',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              },
    
              {
                name: 'Role Permission',
                url: '/home/administration/usermanagement/rolepermission',
                icon: 'fa fa-dot-circle',
                API_CONTROL_URL: '',
                permission: true,
                hasChild: false,
              }
            ]
          },
        ]
      },

      /*
      {
        name: 'Boomgate',
        url: '/home/boomgate',
        icon: 'fa fa-door-open',
        hasChild: true,
        children: [
          {
            name: 'Boomgate',
            url: '/home/boomgate/gate',
            icon: 'fa fa-dot-circle'
          },
          {
            name: 'Transaction',
            url: '/home/boomgate/trxn',
            icon: 'fa fa-dot-circle'
          },
          {
            name: 'QR Code',
            url: '/home/boomgate/qr',
            icon: 'fa fa-dot-circle'
          }
        ]
      },
      */
     
    ];

    //checker and control visibility based on permission key which is then controlled on Home component
    for (var i=0; i<items.length; i++){
      //console.log('data', data);
      let temp = [];
      let control_show = true; //for testing

      if (!items[i].hasChild){
        items[i].permission = control_show && await this.checkPerm(await this.getApiPath(items[i].name), data);
        items[i].API_CONTROL_URL = await this.getApiPath(items[i].name);
      }

      if (items[i].hasChild){
        for (var j=0; j<items[i].children.length; j++){
          let tempinner = [];
            if (!items[i].children[j].hasChild){
              items[i].children[j].permission = control_show && await this.checkPerm(await this.getApiPath(items[i].children[j].name, items[i].name), data);
              items[i].children[j].API_CONTROL_URL = await this.getApiPath(items[i].children[j].name, items[i].name);
              temp.push(items[i].children[j].permission);
            }

            if (items[i].children[j].hasChild){
              for (var k=0; k<items[i].children[j].children.length; k++){
                //console.log(items[i].children[j].children[k].name, await this.checkPerm(await this.getApiPath(items[i].children[j].children[k].name, items[i].children[j].name)), data);
                //console.log('zzzzz');
                items[i].children[j].children[k].permission = control_show && await this.checkPerm(await this.getApiPath(items[i].children[j].children[k].name, `${items[i].name} - ${items[i].children[j].name}`), data);
                items[i].children[j].children[k].API_CONTROL_URL = await this.getApiPath(items[i].children[j].children[k].name, `${items[i].name} - ${items[i].children[j].name}`);
                tempinner.push(items[i].children[j].children[k].permission);
              }

              let tempcheckinner = tempinner.every((item) => {
                return item === false;
              });
      
              if (tempcheckinner){
                items[i].children[j].permission = false; // if all child perm is false, then parent becomes false
              }

              temp.push(items[i].children[j].permission);
            }
        }

        let tempcheck = temp.every((item) => {
          return item === false;
        });

        if (tempcheck){
          items[i].permission = false; // if all child perm is false, then parent becomes false
        }
      }
    }

    //console.log(items);

    return items;
  },

  async checkPerm(path, data){
    /*
      let response = await wsutil.dispatch("checkaccess", {path: path});
      if (response.data.code === 'AP000'){
        return true;
      }

      else{
        return false;
      }
    */

    if (data.data.code === 'AP000'){
      let filterurls = data.data.record.find((item) => {
        return item.Path == path;
      });
  
      if (filterurls !== undefined){
        let itemsasstring = filterurls.TypeRole;
        let itemsasarray = itemsasstring.split(', ');
        let itemsasarraywithoutbrackets = itemsasarray.map(i => i.replace(/[\[\]']+/g,''));
        
        let gettyperole = AppStorage.get('perm').split(",").slice(0,2).join("#");
        console.log('gettyperole', itemsasarraywithoutbrackets);
        let checkifvalid = itemsasarraywithoutbrackets.find(item => item == gettyperole);
  
        if (checkifvalid){
          return true;
        }
  
        else{
          return false;
        }
      }
  
      else{
        return false;
      }
    }

    else{
      return false;
    }
  },

  async getApiPath(menuitem, parent){
    let truth = parent == undefined;
    let parentlocal = '';
    if (!truth) { parentlocal = parent.toLowerCase(); /* console.log('parentlocal', parentlocal) */ }

    //if (menuitem.toLowerCase() === 'dashboard' && parentlocal == '') { return process.env.VUE_APP_DASHBOARD }

    if (menuitem.toLowerCase() === 'country' && parentlocal == 'administration - location') { return process.env.VUE_APP_COUNTRY }
    if (menuitem.toLowerCase() === 'state' && parentlocal == 'administration - location') { return process.env.VUE_APP_STATE }
    if (menuitem.toLowerCase() === 'city' && parentlocal == 'administration - location') { return process.env.VUE_APP_CITY }
    if (menuitem.toLowerCase() === 'postcode' && parentlocal == 'administration - location') { return process.env.VUE_APP_POSTCODE }

    if (menuitem.toLowerCase() === 'operator' && parentlocal == 'business - car park operator') { return process.env.VUE_APP_OPERATOR }
    if (menuitem.toLowerCase() === 'users' && parentlocal == 'business - car park operator') { return process.env.VUE_APP_BUSINESSCPO_USERS }
    if (menuitem.toLowerCase() === 'operator profile details' && parentlocal == 'business - car park operator') { return process.env.VUE_APP_BUSINESSCPO_USERS }
    if (menuitem.toLowerCase() === 'role description' && parentlocal == 'business - car park operator') { return process.env.VUE_APP_BUSINESSCPO_USERS }
    if (menuitem.toLowerCase() === 'create user' && parentlocal == 'business - car park operator') { return process.env.VUE_APP_BUSINESSCPO_USERS_ADD }

    //if (menuitem.toLowerCase() === 'operator' && parentlocal == '') { return process.env.VUE_APP_OPERATOR } //dont add
    if (menuitem.toLowerCase() === 'car park' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARK }
    if (menuitem.toLowerCase() === 'update car park' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARK }
    if (menuitem.toLowerCase() === 'create car park' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARK_ADD }
    if (menuitem.toLowerCase() === 'bays' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARKBAYS }
    if (menuitem.toLowerCase() === 'find my car' && parentlocal == 'car park') { return process.env.VUE_APP_FMC }
    if (menuitem.toLowerCase() === 'car park rate' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARKRATE }
    if (menuitem.toLowerCase() === 'create rate card' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARKRATE_ADD }
    if (menuitem.toLowerCase() === 'update car park rate' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARKRATE }
    if (menuitem.toLowerCase() === 'create car park rate' && parentlocal == 'car park') { return process.env.VUE_APP_CARPARKRATE_ADD }
    if (menuitem.toLowerCase() === 'special car park rate' && parentlocal == 'car park') { return process.env.VUE_APP_SPECIALCARPARKRATE }
    if (menuitem.toLowerCase() === 'service user' && parentlocal == 'car park') { return process.env.VUE_APP_SERVICEUSER }
    if (menuitem.toLowerCase() === 'create service user' && parentlocal == 'car park') { return process.env.VUE_APP_SERVICEUSER_ADD }

    if (menuitem.toLowerCase() === 'system' && parentlocal == 'administration') { return process.env.VUE_APP_SYSTEM }
    if (menuitem.toLowerCase() === 'holiday' && parentlocal == 'administration') { return process.env.VUE_APP_CALENDAR }

    if (menuitem.toLowerCase() === 'internal users' && parentlocal == 'administration - user management') { return process.env.VUE_APP_USERS }
    if (menuitem.toLowerCase() === 'user profile details' && parentlocal == 'administration - user management') { return process.env.VUE_APP_USERS }
    if (menuitem.toLowerCase() === 'admin soc' && parentlocal == 'administration - user management') { return process.env.VUE_APP_USERS }
    if (menuitem.toLowerCase() === 'profile type' && parentlocal == 'administration - user management') { return process.env.VUE_APP_PROFILE_TYPE }
    if (menuitem.toLowerCase() === 'role' && parentlocal == 'administration - user management') { return process.env.VUE_APP_PROFILE_ROLE }
    if (menuitem.toLowerCase() === 'role permission' && parentlocal == 'administration - user management') { return process.env.VUE_APP_ROLEPERMISSION }
    if (menuitem.toLowerCase() === 'admin permission' && parentlocal == 'administration - user management') { return process.env.VUE_APP_ROLEPERMISSION_UPDATE }
    if (menuitem.toLowerCase() === 'manual exit category' && parentlocal == 'administration - parking entry') { return process.env.VUE_APP_ADMIN_CATEGORY }
    if (menuitem.toLowerCase() === 'manual exit issue' && parentlocal == 'administration - parking entry') { return process.env.VUE_APP_ADMIN_ISSUE }

    //if (menuitem.toLowerCase() === 'users' && parentlocal == 'customer') { return process.env.VUE_APP_CUSTOMER_USERS }

    if (menuitem.toLowerCase() === 'profile type' && parentlocal == 'sp console setting - user management') { return process.env.VUE_APP_CUSTOMER_PROFILE_TYPE }
    if (menuitem.toLowerCase() === 'role' && parentlocal == 'sp console setting - user management') { return process.env.VUE_APP_CUSTOMER_PROFILE_ROLE }
    if (menuitem.toLowerCase() === 'role permission' && parentlocal == 'sp console setting - user management') { return process.env.VUE_APP_CUSTOMER_ROLEPERMISSION }
    if (menuitem.toLowerCase() === 'permissions' && parentlocal == 'sp console setting - user management') { return process.env.VUE_APP_CUSTOMER_ROLEPERMISSION_UPDATE }

    if (menuitem.toLowerCase() === 'value pass' && parentlocal == 'marketing - value pass') { return process.env.VUE_APP_VALUEPASS }
    if (menuitem.toLowerCase() === 'value pass units' && parentlocal == 'marketing - value pass') { return process.env.VUE_APP_VALUEPASSUNITS }
    if (menuitem.toLowerCase() === 'create' && parentlocal == 'marketing - value pass') { return process.env.VUE_APP_VALUEPASS_ADD }
    if (menuitem.toLowerCase() === 'update' && parentlocal == 'marketing - value pass') { return process.env.VUE_APP_VALUEPASS }

    if (menuitem.toLowerCase() === 'merchant reward slots' && parentlocal == 'marketing - merchant reward') { return process.env.VUE_APP_MERCHANTREWARDSLOTS }
    if (menuitem.toLowerCase() === 'merchant rewards' && parentlocal == 'marketing - merchant reward') { return process.env.VUE_APP_MERCHANTREWARDS }
    if (menuitem.toLowerCase() === 'review reasons' && parentlocal == 'marketing - merchant reward') { return process.env.VUE_APP_REVIEWREASONS }
    if (menuitem.toLowerCase() === 'collected promo vouchers' && parentlocal == 'marketing - merchant reward') { return process.env.VUE_APP_COLLECTEDPROMOVOUCHERS }
    if (menuitem.toLowerCase() === 'create merchant rewards' && parentlocal == 'marketing - merchant reward') { return process.env.VUE_APP_MERCHANTREWARDS_ADD }
    if (menuitem.toLowerCase() === 'update merchant rewards' && parentlocal == 'marketing - merchant reward') { return process.env.VUE_APP_MERCHANTREWARDS }

    if (menuitem.toLowerCase() === 'sms' && parentlocal == 'message') { return process.env.VUE_APP_SMS }
    if (menuitem.toLowerCase() === 'message category' && parentlocal == 'message - push notification') { return process.env.VUE_APP_MESSAGE }
    if (menuitem.toLowerCase() === 'notification' && parentlocal == 'message - push notification') { return process.env.VUE_APP_NOTIFICATION }
    if (menuitem.toLowerCase() === 'inbox' && parentlocal == 'message - push notification') { return process.env.VUE_APP_INBOX }
    if (menuitem.toLowerCase() === 'new message blast' && parentlocal == 'message') { return process.env.VUE_APP_BLAST }
    if (menuitem.toLowerCase() === 'message template' && parentlocal == 'message - whatsapp') { return process.env.VUE_APP_WHATSAPPMESSAGETEMPLATE }
    if (menuitem.toLowerCase() === 'whatsapp' && parentlocal == 'message - whatsapp') { return process.env.VUE_APP_WHATSAPP }

    if (menuitem.toLowerCase() === 'purchased parking tokens' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_PPT }
    if (menuitem.toLowerCase() === 'transferred parking tokens' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_TPT }
    if (menuitem.toLowerCase() === 'free parking tokens' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_FPT }
    if (menuitem.toLowerCase() === 'deduct parking tokens' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_DPT }
    if (menuitem.toLowerCase() === 'parking' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_PARKING }
    if (menuitem.toLowerCase() === 'lpr parking' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_PARKINGLPR }
    if (menuitem.toLowerCase() === 'special rate parking' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_SPECIALRATEPARKING }
    if (menuitem.toLowerCase() === 'manual exit' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_ME }
    if (menuitem.toLowerCase() === 'manual entry' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_MENTRY }
    if (menuitem.toLowerCase() === 'lpr exit' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_LE }
    if (menuitem.toLowerCase() === 'lpr manual entry' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_LENTRY }
    if (menuitem.toLowerCase() === 'update plate' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_UP }
    if (menuitem.toLowerCase() === 'purchased value pass' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_PVP }
    if (menuitem.toLowerCase() === 'value pass units' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_VPU }
    if (menuitem.toLowerCase() === 'transferred value pass' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_TVP }
    if (menuitem.toLowerCase() === 'used value pass' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_UVP }
    if (menuitem.toLowerCase() === 'history' && parentlocal == 'transaction - mobile subscriber') { return process.env.VUE_APP_TRANSACTIONMOBSUB_H }

    if (menuitem.toLowerCase() === 'ipay88 online' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_IPAY88 }
    if (menuitem.toLowerCase() === 'boost' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_BOOST }
    if (menuitem.toLowerCase() === 'binfinite pay' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_BINFINITE }
    if (menuitem.toLowerCase() === 'touch n go e-wallet' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_TNG }
    if (menuitem.toLowerCase() === 'duit now' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_DN }
    if (menuitem.toLowerCase() === 'touch n go qr' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_TNGQR }
    if (menuitem.toLowerCase() === 'touch n go' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_TNGCARD }
    if (menuitem.toLowerCase() === 'visa master' && parentlocal == 'transaction - payment gateway') { return process.env.VUE_APP_TRANSACTIONPG_VISAMASTER }

    if (menuitem.toLowerCase() === 'all parking' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_AP }
    if (menuitem.toLowerCase() === 'app parking' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_APP }
    if (menuitem.toLowerCase() === 'pole parking' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_PP }
    if (menuitem.toLowerCase() === 'web payment' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_WP }
    if (menuitem.toLowerCase() === 'no payment' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_NP }
    if (menuitem.toLowerCase() === 'manual exit' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_ME }
    if (menuitem.toLowerCase() === 'grace period' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_GP }
    if (menuitem.toLowerCase() === 'manual trigger' && parentlocal == 'transaction - baylock parking') { return process.env.VUE_APP_TRANSACTIONBL_MT }

    if (menuitem.toLowerCase() === 'purchased merchant rewards' && parentlocal == 'transaction - corporate subscriber') { return process.env.VUE_APP_TRANSACTIONCS_PMR }
    if (menuitem.toLowerCase() === 'collected promo vouchers' && parentlocal == 'transaction - corporate subscriber') { return process.env.VUE_APP_TRANSACTIONCS_CPV }
    if (menuitem.toLowerCase() === 'redeemed promo vouchers' && parentlocal == 'transaction - corporate subscriber') { return process.env.VUE_APP_TRANSACTIONCS_RPV }

    if (menuitem.toLowerCase() === 'sent activation code' && parentlocal == 'transaction - sms') { return process.env.VUE_APP_TRANSACTIONSMS_SAC }
    if (menuitem.toLowerCase() === 'verified activation code' && parentlocal == 'transaction - sms') { return process.env.VUE_APP_TRANSACTIONSMS_VAC }
    if (menuitem.toLowerCase() === 'sms notification' && parentlocal == 'transaction - sms') { return process.env.VUE_APP_TRANSACTIONSMS_SN }
    if (menuitem.toLowerCase() === 'all sms' && parentlocal == 'transaction - sms') { return process.env.VUE_APP_TRANSACTIONSMS_AS }

    if (menuitem.toLowerCase() === 'payment' && parentlocal == 'transaction - lpr web') { return process.env.VUE_APP_TRANSACTION_LPRWEB_PAYMENT}

    if (menuitem.toLowerCase() === 'ipay88 online' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_IPAY88 }
    if (menuitem.toLowerCase() === 'boost' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_BOOST }
    if (menuitem.toLowerCase() === 'binfinite pay' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_BINFINITE }
    if (menuitem.toLowerCase() === 'touch n go e-wallet' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_TNG }
    if (menuitem.toLowerCase() === 'duit now' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_DN }
    if (menuitem.toLowerCase() === 'touch n go qr' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_TNGQR }
    if (menuitem.toLowerCase() === 'touch n go' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_TNGCARD }
    if (menuitem.toLowerCase() === 'visa master' && parentlocal == 'report - payment gateway') { return process.env.VUE_APP_REPORTPG_VISAMASTER }

    if (menuitem.toLowerCase() === 'purchased value pass' && parentlocal == 'report - value pass') { return process.env.VUE_APP_REPORTVP_PVP }
    if (menuitem.toLowerCase() === 'used value pass' && parentlocal == 'report - value pass') { return process.env.VUE_APP_REPORTVP_UVP }
    if (menuitem.toLowerCase() === 'transferred value pass' && parentlocal == 'report - value pass') { return process.env.VUE_APP_REPORTVP_TVP }

    if (menuitem.toLowerCase() === 'purchased parking tokens' && parentlocal == 'report - parking tokens') { return process.env.VUE_APP_REPORTPT_PPT }
    if (menuitem.toLowerCase() === 'free parking tokens' && parentlocal == 'report - parking tokens') { return process.env.VUE_APP_REPORTPT_FPT }
    if (menuitem.toLowerCase() === 'deducted parking tokens' && parentlocal == 'report - parking tokens') { return process.env.VUE_APP_REPORTPT_DPT }

    if (menuitem.toLowerCase() === 'casual parking' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_CP }
    if (menuitem.toLowerCase() === 'special rate parking' && parentlocal == 'report - parking') {return process.env.VUE_APP_REPORTP_SRP}
    if (menuitem.toLowerCase() === 'all parking' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_AP }
    if (menuitem.toLowerCase() === 'season parking' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_SP }
    if (menuitem.toLowerCase() === 'manual exit' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_ME }
    if (menuitem.toLowerCase() === 'manual entry' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_MENTRY }
    if (menuitem.toLowerCase() === 'lpr exit' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_LE }
    if (menuitem.toLowerCase() === 'lpr manual entry' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_LENTRY }
    if (menuitem.toLowerCase() === 'update plate' && parentlocal == 'report - parking') { return process.env.VUE_APP_REPORTP_UP }
    
    if (menuitem.toLowerCase() === 'payment' && parentlocal == 'report - lpr web') { return process.env.VUE_APP_REPORT_LPRWEB_PAYMENT }

    if (menuitem.toLowerCase() === 'all parking' && parentlocal == 'report - baylock parking') { return process.env.VUE_APP_REPORTBL_AP }
    if (menuitem.toLowerCase() === 'app casual parking' && parentlocal == 'report - baylock parking') { return process.env.VUE_APP_REPORTBL_APP }
    if (menuitem.toLowerCase() === 'pole casual parking' && parentlocal == 'report - baylock parking') { return process.env.VUE_APP_REPORTBL_PP }
    if (menuitem.toLowerCase() === 'web casual parking' && parentlocal == 'report - baylock parking') { return process.env.VUE_APP_REPORTBL_WP }
    if (menuitem.toLowerCase() === 'no payment' && parentlocal == 'report - baylock parking') { return process.env.VUE_APP_REPORTBL_NP }
    if (menuitem.toLowerCase() === 'manual exit' && parentlocal == 'report - baylock parking') { return process.env.VUE_APP_REPORTBL_ME }
    if (menuitem.toLowerCase() === 'grace period' && parentlocal == 'report - baylock parking') { return process.env.VUE_APP_REPORTBL_GP }

    if (menuitem.toLowerCase() === 'all products' && parentlocal == 'report - operator remittance') { return process.env.VUE_APP_REPORTOR_AP }
    if (menuitem.toLowerCase() === 'all payments' && parentlocal == 'report - operator remittance') { return process.env.VUE_APP_REPORTOR_APAYMENTS }

    if (menuitem.toLowerCase() === 'purchased merchant rewards' && parentlocal == 'report - merchant rewards') { return process.env.VUE_APP_REPORTMR_PMR }
    if (menuitem.toLowerCase() === 'collected promo vouchers' && parentlocal == 'report - merchant rewards') { return process.env.VUE_APP_REPORTMR_CPV }
    if (menuitem.toLowerCase() === 'redeemed promo vouchers' && parentlocal == 'report - merchant rewards') { return process.env.VUE_APP_REPORTMR_RPV }

    if (menuitem.toLowerCase() === 'parking tokens menu' && parentlocal == 'finance - parking tokens') { return process.env.VUE_APP_FINANCEPT_PTM }
    if (menuitem.toLowerCase() === 'free parking tokens' && parentlocal == 'finance - parking tokens') { return process.env.VUE_APP_FINANCEPT_FPT }
    if (menuitem.toLowerCase() === 'deduct parking tokens' && parentlocal == 'finance - parking tokens') { return process.env.VUE_APP_FINANCEPT_DPT }

    if (menuitem.toLowerCase() === 'product services fees' && parentlocal == 'finance - setting') { return process.env.VUE_APP_FINANCES_PSF }
    if (menuitem.toLowerCase() === 'operator remittance finance' && parentlocal == 'finance - setting') { return process.env.VUE_APP_FINANCES_ORF }
    if (menuitem.toLowerCase() === 'binfinite pay tid' && parentlocal == 'finance - setting') { return process.env.VUE_APP_FINANCES_BPT }
    if (menuitem.toLowerCase() === 'duit now' && parentlocal == 'finance - setting') { return process.env.VUE_APP_FINANCES_DN }

    if (menuitem.toLowerCase() === 'tax setting' && parentlocal == 'finance - einvoice') { return process.env.VUE_APP_LHDN_SETUP }
    if (menuitem.toLowerCase() === 'consolidated bills' && parentlocal == 'finance - einvoice') { return process.env.VUE_APP_LHDN_SUBMITTED_DOC }
    if (menuitem.toLowerCase() === 'e-invoice documents' && parentlocal == 'finance - einvoice') { return process.env.VUE_APP_LHDN_EINVOICE_DOCUMENT }

    if (menuitem.toLowerCase() === 'touch n go e-wallet' && parentlocal == 'finance - payment gateway') { return process.env.VUE_APP_FINANCEPG_TNG }
    if (menuitem.toLowerCase() === 'touch n go qr' && parentlocal == 'finance - payment gateway') { return process.env.VUE_APP_FINANCEPG_TNGQR }
    if (menuitem.toLowerCase() === 'duit now' && parentlocal == 'finance - payment gateway') { return process.env.VUE_APP_FINANCEPG_DUITNOW }

    if (menuitem.toLowerCase() === 'all subscribers' && parentlocal == 'subscriber - mobile') { return process.env.VUE_APP_SUBSCRIBER_MOBILE_ALL }
    if (menuitem.toLowerCase() === 'snatch-p' && parentlocal == 'subscriber - mobile') { return process.env.VUE_APP_SUBSCRIBER_MOBILE }
    if (menuitem.toLowerCase() === 'touch n go qr' && parentlocal == 'subscriber - mobile') { return process.env.VUE_APP_SUBSCRIBER_MOBILE_TNGQR }
    if (menuitem.toLowerCase() === 'binfinite pay' && parentlocal == 'subscriber - mobile') { return process.env.VUE_APP_SUBSCRIBER_MOBILE_BP }
    if (menuitem.toLowerCase() === 'car plate' && parentlocal == 'subscriber - mobile') { return process.env.VUE_APP_SUBSCRIBER_MOBILE_CP }
    if (menuitem.toLowerCase() === 'corporate account' && parentlocal == 'subscriber - corporate') { return process.env.VUE_APP_SUBSCRIBER_CORPORATEACCOUNT }
    if (menuitem.toLowerCase() === 'season' && parentlocal == 'subscriber') { return process.env.VUE_APP_SUBSCRIBER_SEASON }
    if (menuitem.toLowerCase() === 'vehicle' && parentlocal == 'subscriber') { return process.env.VUE_APP_SUBSCRIBER_VEHICLE }
    if (menuitem.toLowerCase() === 'lpr web' && parentlocal == 'subscriber') { return process.env.VUE_APP_SUBSCRIBER_LPRWEB }
    if (menuitem.toLowerCase() === 'baylock web' && parentlocal == 'subscriber') { return process.env.VUE_APP_SUBSCRIBER_WLWEB }

    if (menuitem.toLowerCase() === 'gated parking' && parentlocal == 'parking entry') { return process.env.VUE_APP_SUBSCRIBER_PARKINGENTRY }
    if (menuitem.toLowerCase() === 'lpr parking' && parentlocal == 'parking entry') { return process.env.VUE_APP_SUBSCRIBER_LPRPARKINGENTRY }
    if (menuitem.toLowerCase() === 'baylock parking' && parentlocal == 'parking entry') { return process.env.VUE_APP_SUBSCRIBER_BAYLOCKPARKING }

    if (menuitem.toLowerCase() === 'controller' && parentlocal == 'boom gate - controller') { return process.env.VUE_APP_BOOMGATE_CONTROLLER }
    if (menuitem.toLowerCase() === 'boom gate' && parentlocal == 'boom gate') { return process.env.VUE_APP_BOOMGATE_GATE }
    if (menuitem.toLowerCase() === 'offline transaction' && parentlocal == 'boom gate - controller') { return process.env.VUE_APP_BOOMGATE_CONTROLLEROFFLINETRXN }

    if (menuitem.toLowerCase() === 'controller' && parentlocal == 'baylock - controller') { return process.env.VUE_APP_WHEELLOCK_CONTROLLER }
    if (menuitem.toLowerCase() === 'mainboard' && parentlocal == 'baylock') { return process.env.VUE_APP_WHEELLOCK_MAINBOARD }
    if (menuitem.toLowerCase() === 'baylock' && parentlocal == 'baylock') { return process.env.VUE_APP_WHEELLOCK_WHEELLOCK }

    if(menuitem.toLowerCase() === 'parking' && parentlocal == 'parking entry - lpr') {return process.env.VUE_APP_LPR_PARKING }
    if(menuitem.toLowerCase() === 'lpr images' && parentlocal == 'parking entry - lpr') {return process.env.VUE_APP_LPR_PLATE }
    if(menuitem.toLowerCase() === 'lpr unknown' && parentlocal == 'parking entry - lpr') {return process.env.VUE_APP_LPR_PLATE_UNKNOWN }

  },
}